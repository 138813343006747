import Cookies from "js-cookie";

var TokenKey = "Admin-Token";

export function getToken(name) {
  TokenKey = name;

  return Cookies.get(TokenKey);
}

export function setToken(name, token, time = 0) {
  TokenKey = name;

  return Cookies.set(TokenKey, token, { expires: time });
}

export function removeToken(name) {
  TokenKey = name;
  return Cookies.remove(TokenKey);
}
