import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    redirect: "/home",
    component: () => import("@/views/index"),
    hidden: true,
    children: [
      {
        path: "/home",
        component: () => import("@/views/home"),
        hidden: true,
      },
      {
        path: "/mark",
        component: () => import("@/views/mark"),
        hidden: true,
      },
      {
        path: "/virt",
        component: () => import("@/views/virt"),
        hidden: true,
      },
      {
        path: "/aboutUs",
        component: () => import("@/views/aboutUs"),
        hidden: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: "/open",
  routes,
});

export default router;
